import './PageFooter.css';

interface ContainerProps { }

const PageFooter: React.FC<ContainerProps> = () => {
  return (
    <div className="page-footer">
      <p>&copy; Project Novae 2022 </p>
    </div>
  );
};

export default PageFooter;
