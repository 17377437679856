import './ExploreContainer.css';

interface ContainerProps { }

const ExploreContainer: React.FC<ContainerProps> = () => {
  return (
    <div className="container">
      <strong>PROJECT NOVAE</strong>
      {/* <div className="hr"></div> */}
      <p>a creative space</p>
    </div>
  );
};

export default ExploreContainer;
